import React from "react";
import Footer from "components/footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGooglePlay, faAppStoreIos } from '@fortawesome/free-brands-svg-icons'
import * as colors from '../colors';
import './styles.scss'

const iconColor= colors.blue;

const AppStoreIcon = ({href, icon}) => {
  return (
    <div>
       <a href={href}>
          <FontAwesomeIcon icon={icon} color={iconColor} size='5x'/>
        </a>
    </div>
  )
}

const Landing = () => {
  return (
    <React.Fragment>
      <div style={{ height: 100 }} />
      <h1 style={{ textAlign: "center" }}>Hearts vs Minds</h1>
      <img
        height={200}
        src={require("assets/logo.png")}
        className="App-logo"
        alt="logo"
      />
      <div style={{ width: "100%" }}>


        <p>Hearts vs Minds is a tool used to measure the beliefs of a workforce in relation to Agile transformations. By reimaging how managers operate industry-standard psychometric assessment tools, Hearts vs Minds is the first tool of its kind to put the power of psychometric assessment directly in the hands of team managers, business owners, and decision-makers.  </p>

        <div style={{display: 'flex', width: '90%', justifyContent: 'space-around', padding: 30}}>
          <AppStoreIcon icon={faAppStoreIos} href='https://apps.apple.com/us/app/hearts-vs-minds/id1482288438?ls=1'/>
          <AppStoreIcon href='https://play.google.com/store/apps/details?id=com.cityoftalent.heartsvsminds' icon={faGooglePlay}/>
        </div>

        <hr/>

        <strong>Better understand the impact of your business transformation</strong>
        <ul>
          <li>Gain first-hand, empirical data that aggregates employee beliefs and attitudes towards your business transformation. </li>
          <li>16 data points help illustrate the impact of your transformation.</li>
        </ul>

        <strong>Free</strong>
        <ul>
          <li>Significantly reduces the cost of utilising psychometric analysis as a tool in the workplace. Our tool is free and always will be. </li>
        </ul>

        <strong>Fast, no-nonsense testing</strong>
        <ul>
          <li>Quick and easy questionnaires allow for maximum employee engagement and drastically cuts the time needed to complete assessments.</li>
        </ul>

        <strong>Comprehensive breakdowns</strong>
        <ul>
          <li>Instant psychometric reports are generated for each user, providing in-depth and insightful data for decision-makers.</li>
        </ul>

        <hr/>

      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Landing;
