import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Privacy from 'routes/privacy'
import Landing from 'routes/landing';
import 'styles.scss'


function App() {
  return (
    <div className="App">

        <BrowserRouter>

          <Route exact path='/' component={Landing}/>
          <Route exact path='/privacy' component={Privacy}/>
        
        </BrowserRouter>


    </div>
  );
}

export default App;

