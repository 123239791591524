import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>

            <a href='/privacy'>
                Privacy
            </a>

        </div>
    )
}

export default Footer;